import { makeStyles, Tooltip } from "@material-ui/core";
import React from "react";

const useStylesLarge = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.secondary.light,
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.light,
    fontSize: 16,
    color: theme.palette.common.black,
  },
}));

const LightToolTip = ({ children, ...props }) => {
  const classes = useStylesLarge();

  return (
    <Tooltip arrow classes={classes} {...props}>
      {children}
    </Tooltip>
  );
};

export default LightToolTip;
