import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { AnimatePresence } from "framer-motion";
import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { PropagateLoader } from "react-spinners";
import AuthenticatedRoute from "./common/components/AuthenticatedRoute";
import ForgetPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import PatientImages from "./pages/PatientImages";
import SignUp from "./pages/Signup";
import ViewImages from "./pages/ViewImages";
import ViewPatients from "./pages/ViewPatients";
import theme from "./theme";
import { useUser } from "./userprovider";

const Routes = () => {
  const { getCurrentUser } = useUser();
  const [userState, setUserState] = useState({ loading: true, user: null });

  useEffect(async () => {
    let loggedInUser = await getCurrentUser();
    setUserState({ loading: false, user: loggedInUser });
    console.log(userState);
  }, []);

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <PropagateLoader color={"#5383ff"} loading={true} />
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <React.Fragment>
      {userState.loading && <SuspenseLoading></SuspenseLoading>}
      {!userState.loading && (
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <AnimatePresence>
            <Suspense fallback={<SuspenseLoading />}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/signup" component={SignUp} />
                <Route
                  exact
                  path="/forgetpassword"
                  component={ForgetPassword}
                />

                <AuthenticatedRoute
                  key="patients-id"
                  exact
                  path="/patients/:pid"
                  user={userState.user}
                  component={PatientImages}
                />
                <AuthenticatedRoute
                  exact
                  path="/patients"
                  user={userState.user}
                  component={ViewPatients}
                />
                <AuthenticatedRoute
                  exact
                  user={userState.user}
                  path="/viewimages"
                  component={ViewImages}
                />
                <AuthenticatedRoute
                  key="patientimages"
                  exact
                  user={userState.user}
                  path="/patientimages"
                  component={PatientImages}
                />
              </Switch>
            </Suspense>
          </AnimatePresence>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default Routes;
