import { Button, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import FormikTextField from "../../common/components/FormikTextField";
import PageLoader from "../../common/components/PageLoader";
import history from "../../history";
import NonLoginLayout from "../../layouts/NonLoginLayout";
import { ToastError } from "../../layouts/Toast";
import { useUser } from "../../userprovider";
/*
    @params form initial values
 */
const initialValues = {
  email: "",
  password: "",
};
/*
    @params validation schema
 */
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  password: Yup.string().required("Password is required"),
});

export default function Login() {
  const [loading, setLoading] = React.useState(false);
  const { signIn } = useUser();

  /*
    @params form login submit
 */
  const handleSubmit = async (fields) => {
    setLoading(true);

    try {
      let email = fields.email.toLowerCase();
      await signIn(email, fields.password);
      history.push("/patients");
    } catch (e) {
      const { message } = e;
      ToastError(message);
    }
    setLoading(false);
  };

  return (
    <PageLoader isActive={loading}>
      <NonLoginLayout>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid }) => (
            <Form className="SignUp">
              <FormikTextField
                fullWidth
                htmlFor="email"
                name="email"
                placeholder="Enter your email"
                type="email"
                label="Email"
                className="mt-0 mb-3"
                margin="dense"
              />
              <FormikTextField
                fullWidth
                name="password"
                placeholder="Enter your password"
                type="password"
                label="Password"
                className="mt-0 mb-3"
                margin="dense"
              />
              <div className="d-flex justify-content-end flex-column flex-md-row">
                <Typography variant="h4">
                  <Link to="/forgetpassword" color="primary">
                    <span>Forgot password?</span>
                  </Link>
                </Typography>
              </div>

              <div className="text-center mt-3">
                <Button
                  className="px-4"
                  type="submit"
                  disabled={loading || !isValid}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Sign in
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </NonLoginLayout>
    </PageLoader>
  );
}
