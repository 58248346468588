import { Button } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikTextField from "../../common/components/FormikTextField";
import PageLoader from "../../common/components/PageLoader";
import PasswordChecker from "../../common/components/PasswordChecker/index";
import history from "../../history";
import NonLoginLayout from "../../layouts/NonLoginLayout";
import { ToastError, ToastSuccess } from "../../layouts/Toast";

/*
    @params form initial values
 */
const initialValues = {
  email: "",
  password: "",
};

/*
    @params signup form validation schema
 */
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  password: Yup.string().required("Password is required"),
});
/*
    @params confirm  signup form validation schema
 */
const confirmSignupValidation = Yup.object().shape({
  code: Yup.string().required("Verification code is required"),
});

export default function SignUp() {
  const [loading, setLoading] = React.useState(false);
  const [showState, setShowState] = React.useState(1);
  const [email, setEmail] = React.useState("");

  // signup function
  const handleSubmit = async (fields) => {
    setLoading(true);
    try {
      let email = fields.email.toLowerCase();
      await Auth.signUp(email, fields.password);
      ToastSuccess(
        "We have sent you verification code on your email. Please check your email"
      );
      setEmail(email);
      setShowState(2);
    } catch (e) {
      const { message } = e;
      ToastError(message);
    }
    setLoading(false);
  };
  // confirm signup function
  const confirmSignupSubmit = async (fields) => {
    setLoading(true);
    try {
      await Auth.confirmSignUp(email, fields.code);
      ToastSuccess("Signup successfully");
      history.push({
        pathname: "/patients",
      });
    } catch (e) {
      const { message } = e;
      ToastError(message);
    }
    setLoading(false);
  };

  // function render  signup Screen
  function signUpScreen() {
    return (
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid, values }) => (
            <Form className="SignUp">
              <FormikTextField
                fullWidth
                htmlFor="email"
                name="email"
                placeholder="Enter your email"
                type="email"
                label="Email"
                className="mt-0 mb-3"
                margin="dense"
              />
              <FormikTextField
                fullWidth
                name="password"
                placeholder="Enter your password"
                type="password"
                label="Password"
                className="mt-0 mb-3"
                margin="dense"
              />
              <PasswordChecker password={values.password}></PasswordChecker>
              <div className="text-center mt-3">
                <Button
                  className="px-4"
                  type="submit"
                  disabled={loading || !isValid}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Sign up
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }

  // function render confirm signup screen to verify user
  function confirmSignupScreen() {
    return (
      <Formik
        initialValues={{ email: "", code: "" }}
        onSubmit={confirmSignupSubmit}
        validationSchema={confirmSignupValidation}
        validateOnMount
      >
        {({ isValid }) => (
          <Form className="SignUp">
            <FormikTextField
              fullWidth
              htmlFor="email"
              name="email"
              placeholder="Enter your email"
              type="email"
              value={email}
              label="Email"
              className="mt-0 mb-3"
              margin="dense"
            />
            <FormikTextField
              fullWidth
              htmlFor="verification code "
              name="code"
              placeholder="Enter your verification code "
              type="text"
              label="Verification code "
              className="mt-0 mb-3"
              margin="dense"
            />

            <div className="text-center mt-3">
              <Button
                className="px-4"
                type="submit"
                disabled={loading || !isValid}
                variant="contained"
                size="large"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <PageLoader isActive={loading}>
      <NonLoginLayout>
        {showState === 1 && signUpScreen()}
        {showState === 2 && confirmSignupScreen()}
      </NonLoginLayout>
    </PageLoader>
  );
}
