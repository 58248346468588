import { Button, Dialog, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";

const ConfirmationModal = (props) => {
  const [modal, setModal] = useState(props.settings.open);

  useEffect(() => {
    setModal(props.settings.open);
  }, [props.settings.open]);
  const toggle = (success) => {
    setModal(!modal);
    if ("close" in props) {
      props.close(success, props.settings.data);
    }
  };

  return (
    <Dialog
      open={modal}
      onClose={() => toggle(false)}
      fullWidth={true}
      maxWidth="sm"
    >
      <div className="   d-flex justify-content-between">
        <div className="p-3 font-size-lg font-weight-bold">{props.heading}</div>
        <div>
          {props.crossEnabled ? (
            <IconButton onClick={() => toggle(false)}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>
      </div>

      <div className="text-center p-4">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-red m-0 d-130">
            {props.settings.esclamationTriangle ? (
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width="100"
                height="100"
                fill="#F5834E"
                class="bi bi-exclamation-triangle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
            ) : (
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width="80"
                height="75"
                fill="#F04E33"
                className="bi bi-exclamation-circle d-flex align-self-center display-3"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
              </svg>
            )}
          </div>
        </div>
        <h5 className="font-weight-normal mt-0 mb-4 px-4">
          {props?.settings?.dangerouslyHtmlSet ? (
            <div dangerouslySetInnerHTML={{ __html: props.settings.message }} />
          ) : (
            props.settings.message
          )}
        </h5>
        {props?.settings?.subMessage && (
          <p
            className={`${props?.settings?.CustomFontStyle} font-weight-bold mb-0 font-size-md `}
          >
            {props?.settings?.subMessage}
          </p>
        )}

        <div className="pt-4 d-flex  justify-content-center">
          {props.settings.cancel && (
            <Button
              onClick={() => toggle(false)}
              variant="outlined"
              className="mx-1 w-100"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
          )}
          {props?.settings?.ok?.enable && (
            <Button
              onClick={() => toggle(true)}
              variant="contained"
              className={`mx-1 w-100 btn-${props.settings.ok.color}  ${props.settings.ok.background}`}
            >
              <span
                className={`btn-wrapper--label font-weight-bold ${props.settings.ok.textColor}`}
              >
                {props.settings.ok.text || "Ok"}
              </span>
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
