import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { toast } from "react-toastify";
const Msg = ({ message, subtitle, icon }) => (
  <div className="d-flex">
    <div className="ml-2 mr-2">
      <FontAwesomeIcon icon={icon} className="font-size-xxl" />
    </div>
    <div className="d-flex flex-column heading">
      <div className="font-size-lg font-weight-bold">{subtitle}</div>
      <div className="message">{message}</div>
    </div>
  </div>
);
/*
    @params function returning success message
 */
export function ToastSuccess(message) {
  toast.success(
    <Msg message={message} subtitle="Success!" icon={["fas", "check-circle"]} />
  );
}
/*
    @params function returning error message
 */
export function ToastError(message) {
  toast.error(
    <Msg message={message} subtitle="Error!" icon={["fas", "times-circle"]} />
  );
}

/*
    @params function returning warning  message
 */

export function ToastWarning(message) {
  toast.warning(
    <Msg
      message={message}
      subtitle="Warning"
      icon={["fas", "exclamation-triangle"]}
    />
  );
}
/*
    @params function returning info message
 */
export function ToastInfo(message) {
  toast.info(
    <Msg
      message={message}
      subtitle="Information"
      icon={["fas", "info-circle"]}
    />,
    { className: "info-toast" }
  );
}
