import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect } from "react";
import ConfirmationModal from "../../common/components/ModalsConfirmation";
import PageLoader from "../../common/components/PageLoader";
import StyledTable from "../../common/components/StyledTable";
import StyledTableCell from "../../common/components/StyledTableCell";
import StyledTableRow from "../../common/components/StyledTableRow";
import { dateFormat, getErrorMessage } from "../../common/utils/common";
import PageTitle from "../../components/PageTitle";
import LoggedInLayout from "../../layouts/LoggedInLayout";
import { ToastError, ToastInfo, ToastSuccess } from "../../layouts/Toast";
import callHttpAPI from "../../services/api";

const useStyles = makeStyles({
  idColumn: {
    fontSize: 20,
  },
});

const ViewPatients = ({ history, user }) => {
  const classes = useStyles();

  const [patients, setPatients] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [deleteUserModal, setDeleteUserModal] = React.useState({ open: false });

  useEffect(async () => {
    getPatients();
    return () => {
      setPatients([]);
    }; // use effect cleanup
  }, []);
  /*
    @params function to get patients
 */
  const getPatients = async () => {
    setLoading(true);
    try {
      let response = await callHttpAPI("patients", "GET");
      if (response) {
        let { data } = response || {};
        if (data && data.length > 0) {
          setPatients(data);
        }
      }
    } catch (err) {
      ToastInfo(getErrorMessage(err));
    }
    setLoading(false);
  };
  /*
    @params function to delete patients by id
 */
  const deletePatientApi = async (values) => {
    const { id } = values;
    setLoading(true);
    try {
      const response = await callHttpAPI(`patients/${encodeURIComponent(id)}`, "DELETE", null, {});
      if (response?.data) {
        const { message } = response?.data;
        ToastSuccess(message);
        getPatients();
      }
    } catch (err) {
      ToastError(getErrorMessage(err));
    }
    setLoading(false);
  };
  /*
    @params function to show warning when deleting patient
 */
  const handleDelete = async (values) => {
    //To Delete patient
    setDeleteUserModal({
      cancel: true,
      ok: {
        enable: true,
        text: "Delete Patient",
        textColor: "text-white",
        background: "bg-danger",
      },
      esclamationTriangle: false,
      open: true,
      CustomFontStyle: "font-italic",
      message: `Are you sure, you want to delete this patient ?`,
      subMessage: `Once deleted, the patient cannot be restored!`,
      data: values,
      dangerouslyHtmlSet: true,
    });
  };
  return (
    <PageLoader isActive={loading}>
      <LoggedInLayout user={user}>
        <PageTitle title="Your Patients"></PageTitle>
        {patients?.length > 0 && (
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">Patient DOB</StyledTableCell>
                <StyledTableCell align="center">Gender</StyledTableCell>
                <StyledTableCell align="center">
                  Last Updated Date
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patients.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.idColumn}
                  >
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.dob}</StyledTableCell>
                  <StyledTableCell align="center">{row.gender}</StyledTableCell>
                  <StyledTableCell align="center">
                    {dateFormat(row?.lua)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        variant="contained"
                        color="primary"
                        as="Link"
                        href={`/patients/${row.id}`}
                      >
                        Open
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="ml-2"
                        onClick={() => handleDelete(row)}
                      >
                        Delete
                      </Button>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        )}

        {patients && patients.length === 0 && (
          <Typography
            variant="h4"
            className="d-flex justify-content-center p-2"
          >
            <span>No Patients Found!</span>
          </Typography>
        )}
        <ConfirmationModal
          settings={deleteUserModal}
          heading="Delete Patient"
          crossEnabled={true}
          close={(success, data) => {
            if (success) {
              //  something api
              deletePatientApi(data);
            }
            setDeleteUserModal({ ...deleteUserModal, open: false });
          }}
        ></ConfirmationModal>
      </LoggedInLayout>
    </PageLoader>
  );
};

export default ViewPatients;
