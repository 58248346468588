import { css } from "@emotion/core";
import { useState } from "react";
import { ClockLoader } from "react-spinners";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  & {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: transparent;
    box-shadow: rgb(54 215 183) 0px 0px 0px 2px inset;
    border-radius: 50%;
  }
  &:before {
    width: 16.6667px;
    height: 2px;
    top: 24px;
    left: 24px;
    transform-origin: 1px 1px;
    animation: 8s linear 0s infinite normal none running animation-188aqyc;
  }
  &:after {
    width: 20.8333px;
    height: 2px;
    top: 24px;
    left: 24px;
    transform-origin: 1px 1px;
    animation: 2s linear 0s infinite normal none running animation-188aqyc;
  }
  &:before,
  &:after {
    position: absolute;
    content: "";
    background-color: rgb(54, 215, 183);
  }
`;

function App() {
  let [color, setColor] = useState("#ffffff");

  return (
    <div className="sweet-loading">
      <ClockLoader color={color} loading={true} css={override} size={150} />
    </div>
  );
}

export default App;
