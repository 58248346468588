import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function AuthenticatedRoute({ component: C, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <C {...props} user={user} /> : <Redirect to={`/`} />
      }
    />
  );
}
