import { Button, FormControlLabel, Typography } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useState } from "react";
import VerificationModal from "../../common/components/ModalsVerification";
import PageLoader from "../../common/components/PageLoader";
import { getErrorMessage, IMAGE_TYPE } from "../../common/utils/common";
import { ToastError, ToastSuccess } from "../../layouts/Toast";
import callHttpAPI from "../../services/api";

/*
    @params component styles
 */
const useStyles = makeStyles({
  sizing: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
  },
  measurement: {
    color: "#e1a2fe",
  },
});

function PatientImageDetail({ patient, setSpecs }) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [verifyModal, setVerifyModal] = React.useState({ open: false });
  const [patientInfo, setPatientInfo] = useState(patient);
  

  const handleSpecsChange = (event) => {
    setSpecs(event.target.value);
  };
  /*
    @params function  rendering user inputs and labels
 */
  const verifyMeasureFunc = () => {
    setVerifyModal({
      cancel: {
        enable: true,
        text: "Perform manual measurement",
      },
      ok: {
        enable: true,
        text: "I agree with provided measurements",
      },

      open: true,
      CustomFontStyle: "font-bold",
      message: `Verification confirms your agreement with calculated measurement`,

      data: patient.id,
      dangerouslyHtmlSet: true,
    });
  };
  const verificationfunc = async (id) => {
    setLoading(true);
    try {
      const response = await callHttpAPI(
        `images/${id}`,
        "PUT",
        null,
        { verified: true },
        {}
      );
      if (response?.data) {
        setPatientInfo({ ...patientInfo, verified: true });
        getInfo(id);
        ToastSuccess("Measurements verified successfully");
      }
    } catch (err) {
      ToastError(getErrorMessage(err));
    }
    setLoading(false);
  };

  const getInfo = async (id) => {
    setLoading(true);
    try {
      const response = await callHttpAPI(`images/${id}`, "GET");
      if (response?.data) {
        const info = response?.data;
        console.log(info);
        setPatientInfo({
          ...patientInfo,
          verified: info.verified,
          verified_by: info.verified_by,
          verified_on: info.verified_on,
        });
      }
    } catch (err) {
      ToastError(getErrorMessage(err));
    }
    setLoading(false);
  };

  const renderDetail = (column, value, extraClass = '') => {
    return (
      <div className={classes.sizing}>
        <Typography variant="subtitle2" className = {extraClass}>{column}</Typography>
        <Typography className="ml-5" variant="subtitle2">
          {" "}
          {value}
        </Typography>
      </div>
    );
  };

  const renderIntelligentOverlayForSagittal = () => {
    return (
      <div>
      <FormControl component="fieldset" className = "mt-4">
        <FormLabel component="legend"><h6>Use radio buttons to display Intelligent Overlay</h6></FormLabel>
        <RadioGroup aria-label="specs" name="specs" onChange={handleSpecsChange} color="primary">
          <FormControlLabel value="ll" control={<Radio key = "ll"/>} label= {<h5>Lumbar Lordosis</h5>} />
          {renderDetail(
              "Calculated Angle:",
              <h4>{patientInfo && "ll" in patientInfo ? patientInfo?.ll : "-"} &deg;</h4>,
              "ml-4"
          )}
          <FormControlLabel value="ss" control={<Radio key = "ss"/>} label= {<h5>Sacral Slope</h5>} />
          {renderDetail(
              "Calculated Angle:",
              <h4>{patientInfo && "ss" in patientInfo ? patientInfo?.ss : "-"} &deg;</h4>,
              "ml-4"
          )}
          <FormControlLabel value="sp" control={<Radio key = "sp"/>} label={<h5>Spondylolisthesis</h5>} />
          {patientInfo && patientInfo.spondy && patientInfo.spondy.length > 0 && renderSpondyList() }
      
        </RadioGroup>
    </FormControl>
    </div>
    )
  }

  const renderSpondyList = () => {
    return (
      patientInfo.spondy.map((data, index) => {
         return ( 
          <div className="d-flex ml-5">
            <Typography variant="subtitle2" key = {`${index}-0`}> {data[0]}</Typography>
            <Typography variant="subtitle2" key = {`${index}-1`} className = "ml-4"> {data[1]}</Typography>
          </div>
         )
      })
    )
  }

  const formatMeasurementDate = (date) => {
    return moment.utc(date * 1000).format("MM/DD/YYYY");
  };
  return (
    <PageLoader isActive={loading}>
      {renderDetail("Patient DOB", patient.dob)}
      {renderDetail("Patient Height", patient.height)}
      {renderDetail("Patient Sex", patient.gender)}
      
      {patientInfo?.image_view === IMAGE_TYPE.CORONAL && (
        <div>
          <div className=" mt-4">
            <Typography variant="subtitle1"> Proximal Thoracic Curve</Typography>
            {renderDetail(
              "Calculated Cobb Angle:",
              <h4>{patient && "ptc" in patient ? patient?.ptc : "-"} &deg;</h4>
            )}
          </div>
          <div className=" mt-4">
            <Typography variant="subtitle1"> Main Thoracic Curve</Typography>
            {renderDetail(
              "Calculated Cobb Angle:",
              <h4>{patient && "mtc" in patient ? patient?.mtc : "-"} &deg;</h4>
            )}
          </div>
          <div className=" mt-4">
            <Typography variant="subtitle1"> Lumbar Thoracic Curve</Typography>
            {renderDetail(
              "Calculated Cobb Angle:",
              <h4>{patient && "ltc" in patient ? patient?.ltc : "-"} &deg;</h4>
            )}
          </div>
        </div>
      )}
      {patientInfo?.image_view === IMAGE_TYPE.SAGITTAL && 
          renderIntelligentOverlayForSagittal()}
      <br />
      {!patientInfo?.verified && (
        <div>
          <Typography variant="subtitle2" color="error">
            {" "}
            Measurements have not been verified yet!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className="px-5"
            onClick={verifyMeasureFunc}
          >
            Verify
          </Button>
        </div>
      )}
      {patientInfo.verified && (
        <div>
          <Typography variant="h4" className={classes.measurement}>
            {" "}
            Measurements verified by {patientInfo.verified_by} on{" "}
            {formatMeasurementDate(patientInfo.verified_on)}
          </Typography>
        </div>
      )}
      <VerificationModal
        settings={verifyModal}
        heading=""
        crossEnabled={true}
        close={(success) => {
          if (success) {
            verificationfunc(patient.id);
          }
          setVerifyModal({ ...verifyModal, open: false });
        }}
      ></VerificationModal>
    </PageLoader>
  );
}

export default PatientImageDetail;
