import { withStyles } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import React, { Fragment } from "react";

const CssTextField = withStyles((theme) => ({
  root: {
    "& label": {
      fontSize: 22,
      color: "#000000",
      fontWeight: "bold",
    },
    "& label + .MuiInput-root": {
      marginTop: theme.spacing(4),
    },
    "& .MuiInput-underline": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& input": {
      backgroundColor: "#dadbde",
      boxShadow:
        "0 0.22rem 0.525rem rgba($primary, 0.4),0 0.0625rem 0.385rem rgba($primary, 0.54);",
      color: "#8c8c8c",
      fontWeight: "bold",
      fontSize: 18,
      border: "none",
      padding: 5,
    },
  },
}))(TextField);

const FormikTextField = ({ children, render, ...props }) => {
  return (
    <Fragment>
      <Field
        {...props}
        component={CssTextField}
        InputLabelProps={{
          shrink: true,
        }}
      >
        {children}
      </Field>
    </Fragment>
  );
};

export default FormikTextField;
