import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const CCModal = (props) => {
  const classes = useStyles();

  const [modal, setModal] = useState(props.settings.open);

  useEffect(() => {
    setModal(props.settings.open);
  }, [props.settings.open]);
  const toggle = (success) => {
    setModal(!modal);
    if ("close" in props) {
      props.close(success, props.settings.data);
    }
  };

  const styles = props.style;
  return (
    <Dialog
      open={modal}
      onClose={() => toggle(false)}
      style={styles}
      fullWidth={true}
      classes={{
        paper: classes.paper,
      }}
    >
      <div className="p-3  d-flex justify-content-between">
        <div className=" font-size-lg font-weight-bold">
          <Typography variant="h3" className="ml-2">
            {props.heading}
          </Typography>
        </div>
        {props.crossEnabled && (
          <div>
            (
            <IconButton onClick={() => toggle(false)}>
              <CloseIcon />
            </IconButton>
            )
          </div>
        )}
      </div>

      <DialogContent
        className={clsx(` ${classes.height}overflow-auto`)}
        // style={{ height: 300 }}
      >
        <div className={classes.root}>{props.children}</div>

        {props.settings.cancel ||
          (props?.settings?.ok?.enable && (
            <div className="pt-4 pb-3 d-flex  justify-content-center">
              {props.settings.cancel && (
                <Button
                  onClick={() => toggle(false)}
                  variant="outlined"
                  color="secondary"
                  className="mx-1 w-100"
                >
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
              )}
              {props?.settings?.ok?.enable && (
                <button
                  onClick={() => toggle(true)}
                  variant="contained"
                  className={`mx-1 w-100 bg-${props.settings.ok.color}`}
                >
                  <span className="btn-wrapper--label">
                    {props.settings.ok.text || "Ok"}
                  </span>
                </button>
              )}
            </div>
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default CCModal;
