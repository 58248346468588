import { Button, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ConfirmationModal from "../../common/components/ModalsConfirmation";
import PageLoader from "../../common/components/PageLoader";
import StyledTable from "../../common/components/StyledTable";
import StyledTableCell from "../../common/components/StyledTableCell";
import StyledTableRow from "../../common/components/StyledTableRow";
import {
  dateFormat,
  getErrorMessage,
  getImageName
} from "../../common/utils/common";
import PageTitle from "../../components/PageTitle";
import LoggedInLayout from "../../layouts/LoggedInLayout";
import { ToastError, ToastInfo, ToastSuccess } from "../../layouts/Toast";
import callHttpAPI from "../../services/api";
import AddImage from "../AddImage";
import ViewImages from "../ViewImages";
/*
    @params component styles
 */
const useStyles = makeStyles({
  idColumn: {
    fontSize: 20,
  },
  radiocellStyle: {
    backgroundColor: "#ffffff",
    border: "none",
  },
  formControl: {
    width: "74%",
  },
  sizing: {
    position: "absolute",
    bottom: 0,
    right: 50,
    top: 400,
  },
});

const PatientImages = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [patientImages, setPatientImages] = React.useState([]);
  const [deleteImageModal, setDeleteImageModal] = React.useState({
    open: false,
  });

  /*
    @params get image  function
 */
  const getImages = async () => {
    const pid = props?.match?.params.pid;
    setLoading(true);
    try {
      let response = await callHttpAPI(
        pid ? `images?patient_id=${pid}` : "images",
        "GET"
      );
      if (response) {
        let { data } = response || [];
        if (data && data.length > 0) {
          data.forEach((element) => {
            element.selected = false;
          });
          setPatientImages(data);
        } else {
          setPatientImages([]);
        }
      }
    } catch (err) {
      ToastInfo(getErrorMessage(err));
      setPatientImages([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getImages();
    return () => {
      // cleanup;
      setPatientImages([]);
    };
  }, []);

  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState(0);
  const [showImage, setShowImage] = React.useState({ open: false });
  const [compareImage, setComapreImage] = React.useState(false);

  const [addPatientModal, setAddPatientModal] = useState({
    open: false,
    cancel: false,
    message: "",
    ok: {
      enable: false,
    },
  });

  const handleChange = (event, index) => {
    let selectedPatients = [...patientImages];
    selectedPatients[index].selected = event.target?.checked;
    setPatientImages(selectedPatients);

    let counter = event?.target?.checked
      ? selectedValue + 1
      : selectedValue - 1;
    setSelectedValue(counter);
  };
  /*
    @params  function open add image modal
 */
  const addImage = () => {
    setAddPatientModal({
      ...addPatientModal,
      open: true,
    });
  };
  const showImageFunc = (row) => {
    setShowImage({ open: true, row: row });
  };

  const handleCloseAddImage = (response) => {
    setAddPatientModal(response);
    if (
      response &&
      typeof response === "object" &&
      "success" in response &&
      !response.success
    ) {
      return;
    }
    getImages();
  };

  const deleteImageApi = async (values) => {
    const { id } = values;

    setLoading(true);
    try {
      const response = await callHttpAPI(`images/${id}`, "DELETE", null, {});
      if (response?.data) {
        const { message } = response?.data;
        ToastSuccess(message);
        getImages();
      }
    } catch (err) {
      ToastError(getErrorMessage(err));
    }
    setLoading(false);
  };

  const delImageFunc = (row) => {
    setDeleteImageModal({
      cancel: true,
      ok: {
        enable: true,
        text: "Delete Image",
        textColor: "text-white",
        background: "bg-danger",
      },
      esclamationTriangle: false,
      open: true,
      CustomFontStyle: "font-italic",
      message: `Are you sure, you want to delete this image ?`,
      subMessage: `Once deleted, the image cannot be restored!`,
      data: row,
      dangerouslyHtmlSet: true,
    });
  };

  const renderPatientImages = () => {
    return (
      <>
        <div className="d-flex justify-content-end align-items-center ">
          <Button
            variant="contained"
            color="primary"
            className="px-5"
            onClick={addImage}
          >
            Add Image
          </Button>
          <Button
            variant="contained"
            color="primary"
            className=" px-4 mx-3"
            disabled={selectedValue !== 2}
            onClick={() => setComapreImage(true)}
          >
            Compare Selected
          </Button>
        </div>
        {patientImages?.length > 0 && (
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">Weight(lbs)</StyledTableCell>
                <StyledTableCell align="center">Height(in)</StyledTableCell>
                <StyledTableCell align="center"> Uploaded Date</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientImages.map((row, index) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell
                    padding="checkbox"
                    className={classes.radiocellStyle}
                  >
                    <Checkbox
                      icon={<CircleUnchecked />}
                      onChange={(e) => handleChange(e, index)}
                      checkedIcon={<CircleCheckedFilled />}
                      //checked={row?.selected}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.idColumn}
                  >
                    {getImageName(row)}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.weight}</StyledTableCell>
                  <StyledTableCell align="center">{row.height}</StyledTableCell>
                  <StyledTableCell align="center">
                    {dateFormat(row?.upload_date)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        variant="contained"
                        color="primary"
                        className="ml-2"
                        onClick={() => showImageFunc(row)}
                      >
                        View
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="ml-2"
                        onClick={() => delImageFunc(row)}
                      >
                        Delete
                      </Button>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        )}
        {patientImages && patientImages.length === 0 && (
          <Typography
            variant="h4"
            className="d-flex justify-content-center p-2"
          >
            <span>No Images Found!</span>
          </Typography>
        )}
        {patientImages && patientImages.length === 0 && (
          <AddImage
            patient={{ id: props?.match?.params.pid }}
            settings={addPatientModal}
            showLoader={setLoading}
            onClose={(settings) => {
              handleCloseAddImage(settings);
            }}
          ></AddImage>
        )}

        {patientImages && patientImages.length > 0 && (
          <AddImage
            patient={{ ...patientImages[0], id: props?.match?.params.pid }}
            settings={addPatientModal}
            showLoader={setLoading}
            onClose={(settings) => {
              handleCloseAddImage(settings);
            }}
          ></AddImage>
        )}
      </>
    );
  };

  return (
    <PageLoader isActive={loading}>
      <LoggedInLayout user={props.user}>
        { !showImage.open && <PageTitle title={props?.match?.params.pid}></PageTitle> }
        {showImage?.open ? (
          <ViewImages
            images={[showImage.row]}
            onBack={() => setShowImage({ ...showImage, open: false })}
          ></ViewImages>
        ) : compareImage ? (
          <ViewImages
            images={patientImages.filter((item) => item.selected === true)}
            onBack={() => setShowImage({ ...showImage, open: false })}
          ></ViewImages>
        ) : (
          renderPatientImages()
        )}
        <ConfirmationModal
          settings={deleteImageModal}
          heading="Delete Image"
          crossEnabled={true}
          close={(success, data) => {
            if (success) {
              deleteImageApi(data);
            }
            setDeleteImageModal({ ...deleteImageModal, open: false });
          }}
        ></ConfirmationModal>
      </LoggedInLayout>
    </PageLoader>
  );
};

export default withRouter(PatientImages);
