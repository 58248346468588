import { Button, Hidden, Paper, SwipeableDrawer } from "@material-ui/core";
import clsx from "clsx";
import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import history from "../../history";
import AddPatient from "../../pages/AddPatient";
import SidebarFooter from "../SidebarFooter";

const SideBar = (props) => {
  const location = useLocation();
  const [userModal, setUserModal] = useState({
    open: false,
  });
  const {
    sidebarToggleMobile,
    closeDrawer,
    sidebarToggle,
    sidebarHover,
    sidebarFixed,
  } = props;

  /*
    @params rendering patient modal
 */
  const addPatient = () => {
    setUserModal({
      ...userModal,
      open: true,
    });
  };

  /*
    @params function rendering sidebar
 */
  const sidebarMenuContent = (
    <div
      className={clsx("app-sidebar-content", {
        "app-sidebar-nav-close": sidebarToggle && !sidebarHover,
      })}
    >
      <Button variant="contained" color="primary" onClick={addPatient}>
        Add Patient
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/patients")}
      >
        View Patients
      </Button>
    </div>
  );

  return (
    <Fragment>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg"
        >
          {sidebarMenuContent}
          <SidebarFooter></SidebarFooter>
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          className={clsx("app-sidebar-wrapper", {
            "app-sidebar-wrapper-close": sidebarToggle,
            "app-sidebar-wrapper-open": sidebarHover,
            "app-sidebar-wrapper-fixed": sidebarFixed,
          })}
          square
          open={sidebarToggle}
        >
          <div
            className={clsx({
              "app-sidebar-menu": sidebarFixed,
              "app-sidebar-collapsed": sidebarToggle && !sidebarHover,
            })}
          >
            {sidebarMenuContent}
            <SidebarFooter></SidebarFooter>
          </div>
        </Paper>
      </Hidden>

      {/* Add Patient Modal  */}
      <AddPatient
        settings={userModal}
        onClose={(success) => {
          setUserModal({ ...userModal, open: false });
          if (closeDrawer) {
            closeDrawer();
          }
          if (success) {
            // We need to refresh page
            if (location?.pathname === "/patients") {
              window.location.reload();
              return;
            }
            history.push("/patients");
          }
        }}
      ></AddPatient>
    </Fragment>
  );
};

export default SideBar;
