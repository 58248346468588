import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field } from "formik";
import { DatePicker } from "formik-material-ui-pickers";
import React from "react";
const CssTextField = withStyles((theme) => ({
  root: {
    "& label": {
      fontSize: 22,
      color: "#000000",
      fontWeight: "bold",
    },
    "& label + .MuiInput-root": {
      marginTop: theme.spacing(4),
    },
    "& .MuiInput-underline": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& input": {
      backgroundColor: "#dadbde",
      boxShadow:
        "0 0.22rem 0.525rem rgba($primary, 0.4),0 0.0625rem 0.385rem rgba($primary, 0.54);",
      color: "#8c8c8c",
      fontWeight: "bold",
      fontSize: 18,
      border: "none",
      padding: 5,
    },
  },
}))(DatePicker);

const FormikDatePicker = ({ render, ...props }) => {
  const { value, maximumDate, ...rest } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Field
        component={CssTextField}
        {...rest}
        maxDate={maximumDate}
        format="yyyy-MM-dd"
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormikDatePicker;
