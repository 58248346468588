import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import clsx from "clsx";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikTextField from "../../common/components/FormikTextField";
import PageLoader from "../../common/components/PageLoader";
import PasswordChecker from "../../common/components/PasswordChecker";
import PageTitle from "../../components/PageTitle";
import NonLoginLayout from "../../layouts/NonLoginLayout";
import { ToastError, ToastSuccess } from "../../layouts/Toast";
/*
    @params component style
 */
const useStyles = makeStyles({
  minWidth: {
    minWidth: 400,
  },
  maxwidth: {
    maxWidth: 400,
  },
});
/*
    @params form initial values
 */
const initialValues = {
  email: "",
  code: "",
  password: "",
  confirmPassword: "",
};
/*
    @params validation schema  of email
 */
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
});
/*
    @params validation schema of create password
 */
const newPasswordScreenSchema = Yup.object().shape({
  code: Yup.string().required("Code is required"),
  password: Yup.string().required("Enter your new password"),
  confirmPassword: Yup.string()
    .required("You must confirm your new password")
    .test(
      "passwords-match",
      "Your confirmed password does not match the new passowrd above. Please try again",
      function(value) {
        return this.parent.password === value;
      }
    ),
});

export default function ForgetPassword() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [showState, setShowState] = React.useState(1);

  /*
    @params form submit
 */
  const handleSubmit = async (fields) => {
    setLoading(true);
    try {
      let email = fields.email.toLowerCase();
      setEmail(email);
      await Auth.forgotPassword(email);
      setShowState(2);
      ToastSuccess(
        "We have sent you verification code on your email. Please check your email"
      );
    } catch (e) {
      const { message } = e;
      ToastError(message);
    }
    setLoading(false);
  };
  /*
    @params  form submission function  to create new password
 */
  const newPasswordSubmit = async (fields) => {
    const { code, password } = fields;
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      ToastSuccess("Password generated successfully");
    } catch (e) {
      const { message } = e;
      ToastError(message);
    }
    setLoading(false);
  };

  function emailScreen() {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ isValid }) => (
          <Form className="SignUp">
            <PageTitle title="Forget password ?"></PageTitle>

            <FormikTextField
              fullWidth
              htmlFor="email"
              name="email"
              placeholder="Enter your email"
              type="email"
              label="Email"
              className={clsx(` ${classes.minWidth} mt-0 mb-3 mt-3`)}
              margin="dense"
            />

            <div className="text-center mt-3">
              <Button
                className="px-4"
                type="submit"
                disabled={!isValid}
                variant="contained"
                size="large"
                color="primary"
              >
                Reset Password
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  function setNewPasswordScreen() {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={newPasswordSubmit}
        validationSchema={newPasswordScreenSchema}
        validateOnMount
      >
        {({ isValid, values }) => (
          <Form className={clsx(classes.maxwidth)}>
            <PageTitle title="Forget password ?"></PageTitle>

            <FormikTextField
              fullWidth
              htmlFor="email"
              name="email"
              placeholder="Enter your email"
              type="email"
              label="Email"
              value={email}
              className=" mt-0 mb-2 mt-3"
              margin="dense"
            />
            <FormikTextField
              fullWidth
              htmlFor="code"
              name="code"
              placeholder="Enter your verification code"
              type="text"
              label="Verification code"
              className=" mt-0 mb-2 mt-2"
              margin="dense"
            />
            <FormikTextField
              fullWidth
              htmlFor="password"
              name="password"
              placeholder="Enter your new password"
              type="password"
              label="New password"
              className=" mt-0 mb-2 mt-2"
              margin="dense"
            />
            <PasswordChecker password={values.password}></PasswordChecker>
            <FormikTextField
              fullWidth
              htmlFor="confirmPassword"
              name="confirmPassword"
              placeholder="Enter your confirm password"
              type="password"
              label="Confirm password"
              className=" mt-0 mb-3 mt-2"
              margin="dense"
            />

            <div className="text-center mt-3">
              <Button
                className="px-4"
                type="submit"
                disabled={!isValid}
                variant="contained"
                size="large"
                color="primary"
              >
                Set Password
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <PageLoader isActive={loading}>
      <NonLoginLayout>
        {showState === 1 && emailScreen()}
        {showState === 2 && setNewPasswordScreen()}
      </NonLoginLayout>
    </PageLoader>
  );
}
