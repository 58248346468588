import { Grid } from "@material-ui/core";
import React from "react";
import ImageComponent from "../../components/ImageComponent";

function ViewImages({ images, onBack }) {
  return (
    <div className="container">
      <Grid container>
        {images.map((image, index) => {
          return (
            <Grid item xs={12 / images.length}>
              {" "}
              <ImageComponent
                key={index}
                image={image}
                allowZoom={true}
                onBack={onBack}
              ></ImageComponent>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default ViewImages;
