import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useEffect, useState } from "react";
import { FormText } from "react-bootstrap";
import {
  checkForNumbers,
  checkForSymbols,
  checkForUpperCase,
} from "../../utils/common";
import styles from "./index.module.scss";
const DEFAULT_CHECKS = [
  {
    error: "8 Characters Minimum",
    state: false,
  },
  {
    error: "Contains a Capital Letter",
    state: false,
  },
  {
    error: "Contains a Number",
    state: false,
  },
  {
    error: "Contains a Symbol (,./?::!$%&*=+)",
    state: false,
  },
];

const PasswordChecker = ({ errorMessages = DEFAULT_CHECKS, password = "" }) => {
  const [errors, setErrors] = useState(errorMessages);

  useEffect(() => {
    function performCheck() {
      let checks = [...errors];

      for (let item of checks) {
        item.state = false;
      }
      checks[0].state = password.length >= 8 ? true : false;
      checks[1].state = checkForUpperCase(password);
      checks[2].state = checkForNumbers(password);
      checks[3].state = checkForSymbols(password);
      setErrors(checks);
    }
    performCheck();
  }, [password]);
  return (
    <>
      {errors.map((item, index) => {
        return (
          <FormText
            key={index}
            className={!item.state ? styles.passwordError : styles.passwordOK}
          >
            <CheckCircleIcon
              fontSize="small"
              className={`password-check ${styles.passwordCheck} ${
                !item.state ? styles.checkBoxOutline : styles.checkBox
              }`}
            />
            {item.error}
          </FormText>
        );
      })}
    </>
  );
};

export default PasswordChecker;
