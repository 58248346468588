import { Typography } from "@material-ui/core";
import React from "react";
/*
    @params function  rendering page Title  
 */
const PageTitle = (props) => {
  const { title } = props;
  return <Typography variant="h3">{title}</Typography>;
};

export default PageTitle;
