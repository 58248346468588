import { Button, Dialog } from "@material-ui/core";
import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import history from "../../history";
import { useUser } from "../../userprovider";

function IdleTimeContainer({ children }) {
  const { signOut } = useUser();
  const idleTimerRef = useRef(null);
  const sessionTimeOutRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [modalIsopen, setModalIsOpen] = useState(false);
  const IdleTime = 900000;
  const loggedOutTime = 60000;

  const onIdle = () => {
    setModalIsOpen(true);
    sessionTimeOutRef.current = setTimeout(logOut, loggedOutTime);
  };
  const stayActive = () => {
    setModalIsOpen(false);
    clearTimeout(sessionTimeOutRef.current);
  };

  const logOut = async () => {
    setModalIsOpen(false);
    setIsLoggedIn(false);
    await signOut();
    clearTimeout(sessionTimeOutRef.current);
    history.push("/");
  };

  return (
    <div>
      {isLoggedIn && children}
      <Dialog
        open={modalIsopen}
        fullWidth={true}
        maxWidth="sm"
        fullWidth={true}
      >
        <div className="   d-flex justify-content-between">
          <div className="p-3 font-size-lg font-weight-bold">
            <h2>You have been idle from 15 minutes !</h2>
            <p>
              you will be logged out in next 1 minute. please click keep me
              signed in for stay login{" "}
            </p>
          </div>
        </div>

        <div className=" d-flex justify-content-between px-5 mb-4">
          <Button variant="contained" color="primary" onClick={logOut}>
            {" "}
            Log me out{" "}
          </Button>
          <Button variant="contained" color="primary" onClick={stayActive}>
            {" "}
            keep me signed in{" "}
          </Button>
        </div>
      </Dialog>
      <IdleTimer
        ref={idleTimerRef}
        timeout={IdleTime}
        onIdle={onIdle}
      ></IdleTimer>
    </div>
  );
}

export default IdleTimeContainer;
