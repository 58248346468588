import { Button, Grid, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import FormikDatePicker from "../../common/components/FormikDatePicker";
import FormikSelect from "../../common/components/FormikSelect";
import FormikTextField from "../../common/components/FormikTextField";
import UserModal from "../../common/components/Modal";
import PageLoader from "../../common/components/PageLoader";
import { dateToString, getErrorMessage } from "../../common/utils/common";
import { ToastError, ToastSuccess } from "../../layouts/Toast";
import callHttpAPI from "../../services/api";
/*
    @params form initial values 
 */
const initialValues = {
  id: "",
  gender: "",
  dob: null,
};
/*
    @params validation schema 
 */
const validationSchema = Yup.object().shape({
  id: Yup.string().required("Patient Id is required"),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.date()
    .nullable(true)
    .required("DOB is required"),
});
/*
    @params drop down options   
 */
const dropDownOptions = [
  { value: "", label: "Select" },
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

function AddPatient(props) {
  const [loading, setLoading] = useState(false);

  /*
    @params form submit  
 */
  const handleSubmit = async (values) => {
    const { id, gender } = values;
    let dob = dateToString(values?.dob);
    setLoading(true);
    try {
      const response = await callHttpAPI("patients", "POST", null, {
        id,
        dob,
        gender,
      });
      if (response?.data) {
        const { message } = response?.data;
        ToastSuccess(message);
        props.onClose(true);
      }
    } catch (err) {
      ToastError(getErrorMessage(err));
    }
    setLoading(false);
  };
  const renderFormGrid = (label, field, classes) => {
    return (
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="h4">{label}</Typography>
        </Grid>
        <Grid item xs={8} className={classes}>
          {field}
        </Grid>
      </Grid>
    );
  };

  return (
    <PageLoader isActive={loading}>
      <UserModal
        settings={props.settings}
        heading="Add Patient"
        crossEnabled={false}
        close={() => {
          props.onClose(false);
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid }) => (
            <React.Fragment>
              <Form id="myform">
                {renderFormGrid(
                  "Patient ID:",

                  <FormikTextField
                    fullWidth
                    name="id"
                    className={`mt-0 mb-3`}
                    type="text"
                  />
                )}

                {renderFormGrid(
                  "DOB:",

                  <FormikDatePicker
                    fullWidth
                    className={`mt-0 mb-3 `}
                    name="dob"
                    maximumDate={new Date()}
                  ></FormikDatePicker>
                )}
                {renderFormGrid(
                  "Gender:",

                  <FormikSelect
                    fullWidth
                    className="mt-0 mb-3 w-100"
                    name="gender"
                    options={dropDownOptions}
                  />,
                  "select-fw"
                )}
              </Form>
              <div className=" float-right ml-5 mt-5 mb-3">
                <Button
                  form="myform"
                  type="submit"
                  disabled={loading || !isValid}
                  variant="contained"
                  color="primary"
                  className="mx-1 w-100 font-weight-bold"
                >
                  Add
                </Button>
              </div>
            </React.Fragment>
          )}
        </Formik>
      </UserModal>
    </PageLoader>
  );
}

export default AddPatient;
