import { Box } from "@material-ui/core";
import React, { Fragment } from "react";
import Logo from "../../logo.png";
/*
    @params function rendering sidebar footer
 */
export default function SidebarFooter() {
  return (
    <Fragment>
      <Box className="app-sidebar-footer-wrapper">
        <img alt="Mist" className="logo" src={Logo} />
        <div className="mt-2 mb-4">Version 2.00.00</div>
      </Box>
    </Fragment>
  );
}
