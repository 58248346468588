import {
  Button, Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import React, { useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ImageLoader from "../../common/components/ImageLoader";
import config from "../../common/config";
import { getImageName, IMAGE_TYPE } from "../../common/utils/common";
import PatientImageDetail from "../PatientImageDetail";

/*
    @params image Component styles
 */
const useStyles = makeStyles((theme) => ({
  imageCss: {
    maxWidth: "100%",
    objectFit: "contain",
    maxHeight: 600,
  },
  slider: {
    width: "85%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  panImage: {
    overflow: "auto",
    width: "100%",
  },
  sizing: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
  },
}));

export default function ImageComponent({ image, allowZoom = false, onBack }) {
  const classes = useStyles();
  const { CONTENT_URL } = config?.s3;
  const [loading, setLoading] = React.useState(true);
  const [slider, setSlider] = React.useState(0);
  const [overlayCheck, setOverlayCheck] = useState(true);
  const [sagitalSpecs, setSagitalSpecs] = React.useState('');
  const [picture, setPicture] = useState(null);

  useEffect(()=>{
    function load(){
      if(image){
        setLoading(true);
       let pictureToRender =  image?.image_view === IMAGE_TYPE.CORONAL && overlayCheck && image?.modified_url
        ? `${CONTENT_URL}${image.modified_url}` :
      image?.image_view === IMAGE_TYPE.SAGITTAL && sagitalSpecs ?
      `${CONTENT_URL}${image[`${sagitalSpecs}_url`]}` 
        : `${CONTENT_URL}${image.filepath}`;

        setPicture(pictureToRender);
      }
    }
    load();
  },[image, sagitalSpecs, overlayCheck])
  /*
    @params rendering spinner till image is loading
 */
  const renderSpinner = () => {
    if (!loading) {
      // Render nothing if not loading
      return null;
    }
    // render loader
    return <ImageLoader />;
  };

  const handleSliderChange = (event, newValue) => {
    setSlider(newValue);
  };
  const decrSlider = () => {
    let newValue = Math.max(0, slider - 1);
    setSlider(newValue);
  };

  const incrSlider = () => {
    let newValue = Math.min(5, slider + 1);
    setSlider(newValue);
  };

  const handleImageOverlayChange = (event) => {
    setOverlayCheck(event.target.checked);
  };

  const renderDisplayIntelligentOverlay = () => {
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={overlayCheck}
              onChange={handleImageOverlayChange}
              name="checkedB"
              color="primary"
            />
          }
          label={
            <Typography variant="h4">Display Intelligent Overlay</Typography>
          }
        />
      </FormGroup>
    );
  };

  const renderZoomWithImage = () => {
    return (
      <>
        <TransformWrapper
          defaultScale={1}
          centerOnInit={false}
          initialScale={1}
          centerZoomedOut={true}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <React.Fragment>
               <TransformComponent wrapperClass={classes.panImage}>
                <img
                  src={
                    picture
                  }
                  alt="img"
                  height="600px"
                  className={classes.imageCss}
                  onLoad={() =>{ 
                    console.log('Image loaded');
                    resetTransform();
                        setSlider(0);
                    setLoading(false);
                  }}
                  onError={() => setLoading(false)}
                />

              </TransformComponent>
  
              <div className={classes.slider}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        zoomOut();
                        decrSlider();
                      }}
                    >
                      <ZoomOutIcon color="primary" fontSize="large" />
                    </IconButton>
                  </Grid>
                  <Grid item xs>
                    <Slider
                      value={slider}
                      step={1}
                      min={0}
                      max={5}
                      onChange={handleSliderChange}
                      color="primary"
                      aria-labelledby="continuous-slider"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        zoomIn();
                        incrSlider();
                      }}
                    >
                      <ZoomInIcon color="primary" fontSize="large" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        resetTransform();
                        setSlider(0);
                      }}
                    >
                      <AspectRatioIcon color="primary" fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          )}
        </TransformWrapper>
      </>
    );
  };
  const renderImage = () => {
    return (
      <img
        src={`${CONTENT_URL}${image.filepath}`}
        alt="img"
        className={classes.imageCss}
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
      />
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className={`col-md-6 col-xs-6`}>
          <Typography variant="h4" className="pb-2">
            <a onClick={onBack} href="#">
              {image.patient_id}
            </a>{" "}
            / {getImageName(image)}
          </Typography>
          <div>
            {loading && renderSpinner()}
            {allowZoom && renderZoomWithImage()}
            {!allowZoom && renderImage()}
          </div>
        </div>
        <div className="col-md-6 col-xs-6 mt-4">
          <PatientImageDetail patient={image} setSpecs = {setSagitalSpecs}></PatientImageDetail>
          <br />
          {image?.image_view === IMAGE_TYPE.CORONAL &&
          image.modified_url && renderDisplayIntelligentOverlay()}
          <div>
            <div className="d-flex  mt-5 align-items-center ">
              <Button variant="contained" color="primary" className="px-5">
                Export Report
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
