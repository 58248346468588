import { Router } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/base.scss";
import history from "./history";
import Routes from "./Routes";
import { UserProvider } from "./userprovider";
function App() {
  return (
    <UserProvider>
      <Router history={history}>
        <ToastContainer
          draggable
          pauseOnHover
          hideProgressBar={true}
          autoClose={5000}
          position="top-center"
        />
        <Routes />
      </Router>
    </UserProvider>
  );
}

export default App;
