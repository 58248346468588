import { Auth, Storage } from "aws-amplify";
import config from "../config";

const moment = require("moment-timezone");

export function dateFormat(date) {
  return moment.unix(date).format("MM/DD/YYYY");
}
// DateFormat Function
export const dateToString = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

// function getting error messages from api
export function getErrorMessage(err) {
  let message = "Unknown error has occurred. Please contact administrator";
  if (
    err?.response?.data?.message &&
    typeof err?.response?.data?.message === "string"
  ) {
    message = err.response.data.message;
  }
  return message;
}

// function to  upload file
export const uploadFileToS3 = async (file, path) => {
  if (file && file.name && file.type) {
    let result = await Storage.put(`${path}/${file.name}`, file, {
      level: config.s3.STORAGE_LEVEL,
      contentType: file.type,
    });
    if (result && result["key"]) return result["key"];
    return null;
  }
  return null;
};

//function to get file path
export const getFilePath = async (fileName) => {
  let currentUser = await Auth.currentUserInfo();
  if (currentUser && currentUser.id) {
    return `${config.s3.STORAGE_LEVEL}/${currentUser.id}/${fileName}`;
  }
  return "";
};
// function to get image name
export const getImageName = (value) => {
  return value.filepath
    .split("/")
    .slice(-1)[0]
    ?.replace(/\.[^/.]+$/, "");
};

export function checkForUpperCase(inputString) {
  let hasUpperCase = false;
  const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (let i = 0; i < inputString.length; i++) {
    if (
      alpha.includes(inputString[i].toUpperCase()) &&
      inputString[i] === inputString[i].toUpperCase()
    ) {
      hasUpperCase = true;
    }
  }
  return hasUpperCase;
}

/**
 *
 * @param {*} inputString
 * @return {*}
 */
export function checkForNumbers(inputString) {
  if (typeof inputString === "number") {
    return true;
  }
  const numbers = "1234567890";
  let hasNumber = false;
  for (let i = 0; i < inputString.length; i++) {
    if (numbers.includes(inputString[i])) hasNumber = true;
  }
  return hasNumber;
}

/**
 *
 * @param {*} inputString
 * @return {*}
 */
export function checkForSymbols(inputString) {
  const specials = "!@#$%^&*()_+{}:\"<>?|[];',./`~";
  let hasSymbol = false;
  for (let i = 0; i < inputString.length; i++) {
    if (specials.includes(inputString[i])) hasSymbol = true;
  }
  return hasSymbol;
}

export const IMAGE_TYPE = {
  CORONAL: 'coronal',
  SAGITTAL: 'sagittal'
}
