import axios from "axios";
import config from "../common/config";
import { Auth } from "aws-amplify";

// get access token function
const getInit = async () => {
  let currentSession = await Auth.currentCredentials();
  return {
    headers: {
      Authorization: `${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    response: true,
  };
};

// This method is used to call APIs
export default async function callHttpAPI(
  uri,
  method,
  qp = null,
  body = null,
  headers = null
) {
  if (qp) {
    let queryParams = [];
    for (let key in qp) {
      queryParams.push(`${key}=${qp[key]}`);
    }
    if (queryParams && queryParams.length > 0) {
      uri = uri + `?${queryParams.join("&")}`;
    }
  }
  let url = `${config.apiGateway.URL}/${uri}`;

  let params = {
    method,
    url,
  };
  params.headers = { ...(await (await getInit()).headers) };
  if (body) {
    params.data = body;
  }

  return axios(params);
}
