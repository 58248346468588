import {
  faQuestion,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Hidden } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import React, { useEffect } from "react";
import history from "../../history";
import { useUser } from "../../userprovider";
import LightToolTip from "../LightToolTip";
import SideBar from "../Sidebar";

// theme styles of Header
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  bar: {
    background: "#B5B8BC",
    color: "#000000",
  },
  search: {
    position: "relative",
    padding: theme.spacing(1, 1, 1, 0),
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    fontWeight: 700,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Header({ user }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    right: false,
  });

  useEffect(() => {
    console.log(user);
  }, [user]);
  const { signOut } = useUser();

  // Sign out
  const performSignOut = async () => {
    await signOut();
    history.push("/");
  };

  // menu icon toggle
  const toggleDrawer = (anchor, isOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: isOpen });
  };

  // state of menu
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  /*
    @params managing menu state  on mobile view
 */
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  /*
    @params closing menu using state on desktop and mobile view
 */
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  /*
    @params opening menu using state on mobile view
 */
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // menu id
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ></Menu>
  );

  // menu id on mobile view
  const mobileMenuId = "primary-search-account-menu-mobile";

  /*
    @params rendering menu on mobile view
 */
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        {iconButton({ icon: faQuestion })}
        <p className="pt-3">Help</p>
      </MenuItem>
      <MenuItem>
        {iconButton({ icon: faUser })}
        <p className="pt-3">Profile</p>
      </MenuItem>
      <MenuItem onClick={signOut}>
        {iconButton({ icon: faSignOutAlt }, performSignOut)}
        <p className="pt-3">Sign Out</p>
      </MenuItem>
    </Menu>
  );

  /*
    @params function rendering icons
 */
  function iconButton(icon, onClick) {
    return (
      <IconButton className="icon-button" onClick={onClick}>
        <FontAwesomeIcon icon={icon.icon} size="1x" />
      </IconButton>
    );
  }

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.bar} elevation={0}>
        <Toolbar>
          <div className={classes.sectionMobile}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => setState({ ...state, left: true })}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <Typography className={classes.title} variant="h1" noWrap>
            VAPS
          </Typography>
          <Typography className={classes.search} variant="h3">
            Vision Augmented <br />
            Predictive Screening
          </Typography>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div>
              {iconButton({ icon: faQuestion })}
              <LightToolTip
                title={`Signed in as ${user?.attributes?.email}`}
                arrow
              >
                {iconButton({ icon: faUser })}
              </LightToolTip>
              {iconButton({ icon: faSignOutAlt }, performSignOut)}
            </div>
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              className="icon-button"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {/* rendering mobile menu */}
      {renderMobileMenu}
      {/* rendering menu on desktop view  */}
      {renderMenu}
      {/* sidebar  */}
      <Hidden mdUp>
        <SideBar
          sidebarToggleMobile={state["left"]}
          closeDrawer={toggleDrawer("left", false)}
        />
      </Hidden>
    </div>
  );
}
