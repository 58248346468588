import { makeStyles, Paper, Table, TableContainer } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  container: {
    height: "calc(100vh - 200px)",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 0.5em",
  },
});
const StyledTable = ({ label, children }) => {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.container}
    >
      <Table className={classes.table} aria-label={label}>
        {children}
      </Table>
    </TableContainer>
  );
};

export default StyledTable;
