import { TableCell, withStyles } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 700,
    border: "none",
  },
  body: {
    fontSize: 14,
    fontWeight: 700,
  },
}))(TableCell);

export default StyledTableCell;
