import { Card, Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import Logo from "../../logo.png";

const NonLoginLayout = (props) => {
  const { children } = props;
  return (
    <Fragment>
      <div className="flex-grow-1 w-100 d-flex justify-content-center">
        <div className="bg-composed-wrapper--content">
          <div className="hero-wrapper bg-composed-wrapper">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content text-center  ">
                <Grid item className="mx-auto  text-center ">
                  <Card className="p-5 mx-5 text-left d-flex flex-column justify-content-center align-items-center min-vh-100">
                    <img alt="Mist" className="logo non-logged" src={Logo} />
                    {children}
                  </Card>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NonLoginLayout;
