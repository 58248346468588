import { Button, Grid, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import FormikDatePicker from "../../common/components/FormikDatePicker";
import FormikSelect from "../../common/components/FormikSelect";
import FormikTextField from "../../common/components/FormikTextField";
import UserModal from "../../common/components/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  dateToString,
  getErrorMessage,
  getFilePath,
  uploadFileToS3,
} from "../../common/utils/common";
import { ToastError, ToastSuccess } from "../../layouts/Toast";
import callHttpAPI from "../../services/api";
/*
    @params dropdown options
 */
const dropDownOptions = [
  { value: "", label: "Select" },
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

/*
    @params form initial values
 */
const initialValues = {
  id: "",
  gender: "",
  height: "",
  weight: "",
  dob: null,
  filepath: "",
  imageView: "",
};
/*
    @params validation schema
 */
const validationSchema = Yup.object().shape({
  id: Yup.string().required("Patient Id is required"),
  gender: Yup.string().required("Gender is required"),
  height: Yup.number()
    .integer()
    .typeError("Height must be a number")
    .min(1, "Minimum Height 1 inches")
    .max(96, "Maxmimum height is 96 inches")
    .required("Height is required"),
  weight: Yup.number()
    .typeError("Weight must be a number")
    .required("Weight is required")
    .min(1, "Weight must be 1 lbs")
    .max(1500, "Weight should be less than 1500 lbs ")
    .test("", "only 2 decimal places is allowed", (value) =>
      (value + "").match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/)
    ),
  filepath: Yup.string().required("File is required."),
  imageView: Yup.string(),
  dob: Yup.date()
    .nullable(true)
    .required("DOB is required"),
});

const AddImage = (props) => {
  const [inputElement, setInputElement] = useState(null);
  const [file, setFile] = useState(null);
  const [patients, setPatients] = useState(initialValues);
  const initializePatient = (patient = null) => {
    return {
      id: patient?.id,
      gender: patient?.gender,
      height: "",
      weight: "",
      dob: patient?.dob,
      filepath: "",
      imageView: "",
    };
  };

  useEffect(() => {
    async function load() {
      let patient;
      if (props?.settings?.open) {
        props.showLoader(true);
        try {
          let { data } = await callHttpAPI(
            `patients/${props?.patient?.id}`,
            "GET"
          );
          if (data) patient = data;
        } catch (err) {
          console.log("err in calling patient");
        }
        props.showLoader(false);
      }
      setPatients(initializePatient(patient));
    }
    load();
  }, [props?.settings?.open]);

  // This function is used to open file
  const openFile = () => {
    if (inputElement) {
      inputElement.click();
    }
  };

  /*
    @params submit form function
 */
  const handleSubmit = async (values) => {
    values.dob = values.dob ? dateToString(values.dob) : "";
    props.showLoader(true);
    try {
      if (file) {
        let result = await uploadFileToS3(file, values.id);
        if (result) {
          values.filepath = await getFilePath(result);
          const response = await callHttpAPI("images", "POST", null, values);
          if (response?.data) {
            const { message } = response?.data;
            ToastSuccess(message);
            props.onClose(true);
            setPatients(initialValues);
            setFile("");
          }
        }
      }
    } catch (err) {
      ToastError(getErrorMessage(err));
    }
    props.showLoader(false);
  };

  const renderFormGrid = (label, field, button, classes) => {
    return (
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="h4">{label}</Typography>
        </Grid>
        <Grid item xs={4} className={classes}>
          {field}
        </Grid>
        <Grid item xs={4}>
          {button}
        </Grid>
      </Grid>
    );
  };

  return (
    <UserModal
      settings={props.settings}
      heading="Import Image"
      crossEnabled={false}
      close={(success) => {
        props.onClose({ ...props.settings, open: false, success });
        setPatients(initializePatient());
        setFile("");
      }}
    >
      <Formik
        initialValues={patients}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize={true}
      >
        {({ isValid, setFieldValue, values, handleChange }) => (
          <React.Fragment>
            <Form id="myform">
              {renderFormGrid(
                "Filepath:",

                <React.Fragment>
                  <FormikTextField
                    className={`mt-0 mb-3 `}
                    name="filepath"
                    disabled
                    id="contained-button-file"
                  />
                  <input
                    ref={(input) => setInputElement(input)}
                    type="file"
                    accept="image/*"
                    className="invisible position-absolute"
                    style={{ width: 0 }}
                    onChange={(event) => {
                      setFieldValue(
                        "filepath",
                        event &&
                          event.currentTarget &&
                          event.currentTarget.files &&
                          event.currentTarget.files.length > 0 &&
                          event.currentTarget.files[0].name
                          ? event.currentTarget.files[0].name
                          : ""
                      );
                      setFile(event.target.files[0]);
                    }}
                  />
                </React.Fragment>,
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className="ml-3"
                  onClick={openFile}
                >
                  Browse
                </Button>
              )}
              {renderFormGrid(
                "Patient ID:",

                <FormikTextField
                  name="id"
                  className={`mt-0 mb-3`}
                  type="text"
                />
              )}

              {renderFormGrid(
                "DOB:",
                <FormikDatePicker
                  className={`mt-0 mb-3 `}
                  name="dob"
                  maximumDate={new Date()}
                  disabled={patients.dob ? true : false}
                ></FormikDatePicker>
              )}

              {renderFormGrid(
                "Gender:",

                <FormikSelect
                  fullWidth
                  className="mt-0 mb-3 w-100"
                  name="gender"
                  disabled={patients.gender ? true : false}
                  value={patients?.gender}
                  options={dropDownOptions}
                />,
                null,
                "select-fw"
              )}
              {renderFormGrid(
                "Height (in):",

                <FormikTextField
                  name="height"
                  className={`mt-0 mb-3`}
                  type="text"
                  disabled={patients.height ? true : false}
                />
              )}
              {renderFormGrid(
                "Weight (lbs):",

                <FormikTextField
                  name="weight"
                  className={`mt-0 mb-3`}
                  type="text"
                  disabled={patients.weight ? true : false}
                />
              )}

              {renderFormGrid(
                "Image View: ",
                <FormControl className="mt-0">
                  <RadioGroup aria-label="imageView" name="imageView">
                    <div className="row">
                      <FormControlLabel
                        value="sagittal"
                        control={<Radio color="primary" />}
                        label="Sagittal"
                        onChange={(event) => {
                          setFieldValue("imageView", event.target.value);
                        }}
                      />
                      <FormControlLabel
                        value="coronal"
                        control={<Radio color="primary" />}
                        label="Coronal"
                        onChange={(event) => {
                          setFieldValue("imageView", event.target.value);
                        }}
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              )}
            </Form>
            <div className=" float-right ml-5 mt-5 mb-3">
              <Button
                form="myform"
                type="submit"
                disabled={!isValid}
                variant="contained"
                color="primary"
                className="mx-1 w-100  font-weight-bold"
              >
                Import{" "}
              </Button>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </UserModal>
  );
};

export default AddImage;
