import { Auth } from "aws-amplify";
import React, { useState } from "react";
export const UserContext = React.createContext(null);
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  /*
    @params get current login user 
 */
  const getCurrentUser = async () => {
    let loggedInUser = null;
    try {
      loggedInUser = await Auth.currentUserInfo();
      if (loggedInUser) {
        setUser(loggedInUser);
      } else {
        setUser(null);
      }
    } catch (err) {
      setUser(null);
    }
    return loggedInUser;
  };
  // This method is used to get user data
  const signIn = async (email, password) => {
    await Auth.signIn(email, password);
    await getCurrentUser();
  };
  /*
    @params user signout function 
 */
  const signOut = async () => {
    await Auth.signOut();
    setUser(null);
  };
  const values = React.useMemo(
    () => ({
      user,
      signIn,
      signOut,
      getCurrentUser,
    }),
    [user]
  );
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      "`useUser` hook must be used within a `UserProvider` component"
    );
  }
  return context;
};
