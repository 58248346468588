import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { BeatLoader, PropagateLoader } from "react-spinners";
const PageLoader = ({ isActive, children, individual = false }) => {
  return (
    <LoadingOverlay
      active={isActive}
      spinner={
        individual ? (
          <BeatLoader color={"#FFFFFF"} />
        ) : (
          <PropagateLoader color={"#FFFFFF"} />
        )
      }
      styles={{
        overlay: (base) => {
          let style = { ...base, zIndex: 2030 };
          if (!individual) {
            style.minHeight = "100vh";
            style.position = "fixed";
          }
          return style;
        },
      }}
    >
      {children}
    </LoadingOverlay>
  );
};

export default PageLoader;
