import clsx from "clsx";
import React from "react";
import IdleTimerContainer from "../../common/components/IdleTimerContainer";
import Header from "../../components/Header";
import SideBar from "../../components/Sidebar";

const LoggedInLayout = ({ children, user }) => {
  return (
    <IdleTimerContainer>
      <div className={clsx("app-wrapper")}>
        <Header user={user} />
        <div className="app-main">
          <SideBar sidebarFixed={true} />
          <div
            className={clsx(
              "app-content app-content app-content-sidebar-fixed"
            )}
          >
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </IdleTimerContainer>
  );
};

export default LoggedInLayout;
