// config file
const variables = {
  dev: {
    apiGateway: {
      REGION: "us-east-2",
      // api url
      URL: "https://u7p7i30ijk.execute-api.us-east-2.amazonaws.com/dev",
    },
    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_PIhFlgvag",
      //user pool client ID
      APP_CLIENT_ID: "61vi7bbeeepo9nhsq72dvdah27",
      IDENTITY_POOL_ID: "us-east-2:e33d97e4-ae69-4fb8-bdad-6967ced659d4",
    },
    s3: {
      REGION: "us-east-2",
      BUCKET: `${process.env.REACT_APP_STAGE || "dev"}-content-mist`,
      STORAGE_LEVEL: "private",
      CONTENT_URL: `https://${process.env.REACT_APP_STAGE ||
        "dev"}-content-mist.s3.us-east-2.amazonaws.com/`,
    },
  },
};

const stage = process.env.REACT_APP_STAGE || "dev";
export default {
  ...variables[stage],
};
