import { FormControl, withStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Field } from "formik";
import React from "react";
import FormikTextField from "./FormikTextField";
const CssSelectField = withStyles((theme) => ({
  formControl: {
    fullWidth: true,
  },
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#dadbde",
      borderRadius: 1,
      height: 31,
    },
    "& .MuiSelect-root": {
      width: 100,
      padding: 10,
    },
  },
}))(FormikTextField);
const FormikSelect = ({ children, render, ...props }) => {
  const { label, name, options, value, ...rest } = props;
  return (
    <FormControl>
      {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}

      <Field
        variant="filled"
        as={CssSelectField}
        select
        id={name}
        name={name}
        value = { !value ? "": value }
        {...rest}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Field>
    </FormControl>
  );
};

export default FormikSelect;
