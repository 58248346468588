import { Button, Dialog, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  MuiDialogPaper: {
    color: "#dadbde",
  },
});

const VerificationModal = (props) => {
  const classes = useStyles();
  const [modal, setModal] = useState(props.settings.open);

  useEffect(() => {
    setModal(props.settings.open);
  }, [props.settings.open]);
  const toggle = (success) => {
    setModal(!modal);
    if ("close" in props) {
      props.close(success, props.settings.data);
    }
  };

  return (
    <Dialog
      open={modal}
      onClose={() => toggle(false)}
      fullWidth={true}
      maxWidth="md"
      className={classes.MuiDialogPaper}
    >
      <div className="   d-flex justify-content-between">
        <div className="p-3 font-size-lg font-weight-bold">{props.heading}</div>
        <div>
          {props.crossEnabled ? (
            <IconButton
              color="primary"
              variant="outlined"
              onClick={() => toggle(false)}
            >
              <CancelIcon fontSize="large" />
            </IconButton>
          ) : null}
        </div>
      </div>

      <div className="text-center p-4">
        <h5 className="font-weight-bold mt-0 mb-4 px-4">
          {props?.settings?.dangerouslyHtmlSet ? (
            <div dangerouslySetInnerHTML={{ __html: props.settings.message }} />
          ) : (
            props.settings.message
          )}
        </h5>
        <div className="pt-4 d-flex  justify-content-center">
          {props?.settings?.ok?.enable && (
            <Button
              onClick={() => toggle(true)}
              variant="contained"
              color="primary"
              className={`mx-1 w-100 btn-primary`}
            >
              <span
                className={`btn-wrapper--label font-weight-bold ${props.settings.ok.textColor}`}
              >
                {props.settings.ok.text || "Ok"}
              </span>
            </Button>
          )}
          {props?.settings?.cancel?.enable && (
            <Button
              onClick={() => toggle(false)}
              variant="contained"
              color="primary"
              className={`mx-1 w-100 btn-primary`}
            >
              <span className="btn-wrapper--label font-weight-bold">
                {props.settings.cancel.text || "Cancel"}
              </span>
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default VerificationModal;
